<template>
  <a-drawer
    :title="title"
    :maskClosable="true"
    :width="drawerWidth"
    placement="right"
    :closable="true"
    @close="handleCancel"
    :visible="visible"
    style="height: 100%; overflow: auto; padding-bottom: 53px"
  >
    <template slot="title">
      <div style="width: 100%">
        <span>{{ title }}</span>
        <span style="display: inline-block; width: calc(100% - 51px); padding-right: 10px; text-align: right">
          <a-button @click="toggleScreen" icon="appstore" style="height: 20px; width: 20px; border: 0px"></a-button>
        </span>
      </div>
    </template>

    <div class="flex jc-end" >
      <a-button type="primary" @click="editInfo">编辑</a-button>
    </div>

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="阶段序号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :min="1"
            placeholder="阶段序号"
            v-decorator.trim="['sequenceNo', { rules: [{ required: true, message: '请输入阶段序号' }] }]"
          />
        </a-form-item>
        <a-form-item label="阶段名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            placeholder="阶段名称"
            v-decorator.trim="['stageName', validatorRules.stageName]"
          />
        </a-form-item>

        <a-form-item label="阶段类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            mode="single"
            v-decorator="['stageType', { rules: [{ required: true, message: '请选择阶段类型' }] }]">
            <a-select-option :value="1">普通阶段</a-select-option>
            <a-select-option :value="2">特殊阶段</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="职务" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-position returnId placeholder="请选择职务" :multiple="false" v-decorator="['positionId', { rules: [{ required: true, message: '请选择职务' }] }]" />
        </a-form-item>

        <!--部门分配-->
        <a-form-item label="部门分配" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="!departDisabled">
          <role-tree 
            :defaultDepartKeys="defaultDepartKeys" 
            @change="treeChange"
          />
        </a-form-item>
      </a-form>
    </a-spin>
    <depart-window ref="departWindow" @ok="modalFormOk"></depart-window>

    <div class="drawer-bootom-button" v-show="isEdit">
      <a-popconfirm title="确定放弃编辑？" @confirm="handleCancel" okText="确定" cancelText="取消">
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="confirmLoading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment'
import Vue from 'vue'
// 引入搜索部门弹出框的组件
import departWindow from './DepartWindow'
import JSelectPosition from '@/components/jeecgbiz/JSelectPosition'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getAction } from '@/api/manage'
import { addStage, editStage } from '@/api/api'
import { disabledAuthFilter } from '@/utils/authFilter'
import { duplicateCheck } from '@/api/api'
import RoleTree from '@/views/system/components/RoleTree'
export default {
  name: 'StageModal',
  components: {
    departWindow,
    JSelectPosition,
    RoleTree,
  },
  data() {
    return {
      departDisabled: false, //是否是我的部门调用该页面
      roleDisabled: false, //是否是角色维护调用该页面
      modalWidth: 800,
      drawerWidth: 700,
      modaltoggleFlag: true,
      confirmDirty: false,
      isEdit: false,
      departList:[],
      defaultDepartKeys: [],
      selectedDepartKeys: [], //保存用户选择部门id
      checkedDepartKeys: [],
      checkedDepartNames: [], // 保存部门的名称 =>title
      checkedDepartNameString: '', // 保存部门的名称 =>title
      resultDepartOptions: [],
      stageId: '', //保存阶段id
      disableSubmit: false,
      stageDepartModel: { stageId: '', departIdList: [] }, // 保存SysUserDepart的用户部门中间表数据需要的对象
      dateFormat: 'YYYY-MM-DD',
      validatorRules: {
        stageName: {
          rules: [
            { required: true, message: '请输入阶段名称!', },
            // { validator: this.validateStageName, },
          ],
        },
      },
      departIdShow: false,
      departIds: [], //负责部门id
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      uploadLoading: false,
      confirmLoading: false,
      headers: {},
      form: this.$form.createForm(this),
      picUrl: '',
      url: {
        stageWithDepart: '/stage/stageDepartList', // 引入为指定阶段查看部门信息需要的url
      },
      fileList: [],
      tenantList: [],
      currentTenant: [],
    }
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN)
    this.headers = { 'X-Access-Token': token }
  },
  computed: {
    uploadAction: function () {
      return this.url.fileUpload
    },
  },
  methods: {
    isDisabledAuth(code) {
      return disabledAuthFilter(code)
    },
    editInfo() {
      this.isEdit = true;
    },
    treeChange(keys,list) {

      this.model.departments = list.map(item => {
        return {
          departId: item.key,
          departName: item.title
        }
      })
    },
    //窗口最大化切换
    toggleScreen() {
      if (this.modaltoggleFlag) {
        this.modalWidth = window.innerWidth
      } else {
        this.modalWidth = 800
      }
      this.modaltoggleFlag = !this.modaltoggleFlag
    },
    refresh() {
      this.selectedDepartKeys = []
      this.checkedDepartKeys = []
      this.checkedDepartNames = []
      this.checkedDepartNameString = ''
      this.stageId = ''
      this.dataDepartOptions = []
      this.departId = []
      this.departIdShow = false
      this.currentTenant = []
    },
    add() {
      this.picUrl = ''
      this.refresh()
      this.edit({ activitiSync: '1' })
    },
    edit(record) {
      this.resetScreenSize() // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
      let that = this
      that.checkedDepartNameString = ''
      that.form.resetFields()
      that.stageId = record.stageId
      that.sequenceNo = record.sequenceNo
      that.visible = true
      that.model = Object.assign({}, record)
      that.$nextTick(() => {
        that.form.setFieldsValue(pick(this.model, 'stageId', 'sequenceNo', 'stageName', 'stageType', 'positionId'))
      })
      // 调用查询用户对应的部门信息的方法
      that.checkedDepartKeys = []
      that.loadCheckedDeparts()
    },
    //
    loadCheckedDeparts() {
      let that = this
      if (!that.stageId) {
        return
      }
      getAction(that.url.stageWithDepart, { stageId: that.stageId }).then((res) => {
        that.checkedDepartNames = []
        if (res.success) {
          var depart = []
          var departId = []
          for (let i = 0; i < res.data.length; i++) {
            that.checkedDepartNames.push(res.data[i].title)
            this.checkedDepartNameString = this.checkedDepartNames.join(',')
            that.checkedDepartKeys.push(res.data[i].key)
            //新增负责部门选择下拉框
            depart.push({
              key: res.data[i].key,
              title: res.data[i].title,
            })
            departId.push(res.data[i].key)
          }
          that.dataDepartOptions = depart
          //判断部门id是否存在，不存在择直接默认当前所在部门
          if (this.model.departIds) {
            this.departIds = this.model.departIds.split(',')
          } else {
            this.departIds = departId
          }
          that.stageDepartModel.departIdList = that.checkedDepartKeys
        } else {
          console.log(res.message)
        }
      })
    },
    close() {
      this.$emit('close')
      this.visible = false
      this.disableSubmit = false
      this.selectedRole = []
      this.stageDepartModel = { stageId: '', departIdList: [] }
      this.checkedDepartNames = []
      this.checkedDepartNameString = ''
      this.checkedDepartKeys = []
      this.selectedDepartKeys = []
      this.dataDepartOptions = []
      this.departIds = []
      this.departIdShow = false
      this.fileList = []
      this.model.departments = []
    },
    moment,
    handleSubmit() {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          let formData = Object.assign(this.model, values)
          // formData.departments = []
          // if (this.stageDepartModel.departIdList.length > 0) {
          //   this.stageDepartModel.departIdList.forEach((element) => {
          //     formData.departments.push({ departId: element })
          //   })
          // }
          let obj
          if (!this.model.stageId) {
            obj = addStage(formData)
          } else {
            obj = editStage(formData)
          }
          obj
            .then((res) => {
              if (res.success) {
                that.$message.success(res.message)
                that.$emit('ok')
              } else {
                that.$message.warning(res.message)
              }
            })
            .finally(() => {
              that.confirmLoading = false
              that.checkedDepartNames = []
              that.stageDepartModel.departIdList = { stageId: '', sequenceNo: '', departIdList: [] }
              that.close()
            })
        }
      })
    },
    handleCancel() {
      this.close()
    },
    validateStageName(rule, value, callback) {
      var params = {
        tableName: 'mx_stage',
        fieldName: 'stage_name',
        fieldVal: value,
        dataId: this.stageId,
      }
      duplicateCheck(params).then((res) => {
        if (res.success) {
          callback()
        } else {
          callback('阶段名已存在!')
        }
      })
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    handleChange(info) {
      this.picUrl = ''
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        this.uploadLoading = false
        console.log(response)
        if (response.success) {
          this.model.avatar = response.message
          this.picUrl = 'Has no pic url yet'
        } else {
          this.$message.warning(response.message)
        }
      }
    },
    // 搜索用户对应的部门API
    onSearch() {
      this.$refs.departWindow.add(this.checkedDepartKeys, this.userId)
    },

    // 获取用户对应部门弹出框提交给返回的数据
    modalFormOk(formData) {
      this.checkedDepartNames = []
      this.selectedDepartKeys = []
      this.checkedDepartNameString = ''
      // this.stageId = formData.stageId
      // this.stageDepartModel.stageId = formData.stageId
      this.departIds = []
      this.dataDepartOptions = []
      var depart = []
      for (let i = 0; i < formData.departIdList.length; i++) {
        this.selectedDepartKeys.push(formData.departIdList[i].key)
        this.checkedDepartNames.push(formData.departIdList[i].title)
        this.checkedDepartNameString = this.checkedDepartNames.join(',')
        //新增部门选择，如果上面部门选择后不为空直接付给负责部门
        depart.push({
          key: formData.departIdList[i].key,
          title: formData.departIdList[i].title,
        })
        this.departIds.push(formData.departIdList[i].key)
      }
      this.dataDepartOptions = depart
      this.stageDepartModel.departIdList = this.selectedDepartKeys
      this.checkedDepartKeys = this.selectedDepartKeys //更新当前的选择keys
    },
    // 根据屏幕变化,设置抽屉尺寸
    resetScreenSize() {
      let screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.drawerWidth = screenWidth
      } else {
        this.drawerWidth = 700
      }
    },
    identityChange(e) {
      if (e.target.value === '1') {
        this.departIdShow = false
      } else {
        this.departIdShow = true
      }
    },
  },
}
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>