var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      staticStyle: {
        height: "100%",
        overflow: "auto",
        "padding-bottom": "53px",
      },
      attrs: {
        title: _vm.title,
        maskClosable: true,
        width: _vm.drawerWidth,
        placement: "right",
        closable: true,
        visible: _vm.visible,
      },
      on: { close: _vm.handleCancel },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                width: "calc(100% - 51px)",
                "padding-right": "10px",
                "text-align": "right",
              },
            },
            [
              _c("a-button", {
                staticStyle: { height: "20px", width: "20px", border: "0px" },
                attrs: { icon: "appstore" },
                on: { click: _vm.toggleScreen },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "flex jc-end" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.editInfo } },
            [_vm._v("编辑")]
          ),
        ],
        1
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "阶段序号",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: [
                          "sequenceNo",
                          {
                            rules: [
                              { required: true, message: "请输入阶段序号" },
                            ],
                          },
                        ],
                        expression:
                          "['sequenceNo', { rules: [{ required: true, message: '请输入阶段序号' }] }]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { min: 1, placeholder: "阶段序号" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "阶段名称",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["stageName", _vm.validatorRules.stageName],
                        expression: "['stageName', validatorRules.stageName]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: "阶段名称" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "阶段类型",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "stageType",
                            {
                              rules: [
                                { required: true, message: "请选择阶段类型" },
                              ],
                            },
                          ],
                          expression:
                            "['stageType', { rules: [{ required: true, message: '请选择阶段类型' }] }]",
                        },
                      ],
                      attrs: { mode: "single" },
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("普通阶段"),
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("特殊阶段"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "职务",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("j-select-position", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "positionId",
                          {
                            rules: [{ required: true, message: "请选择职务" }],
                          },
                        ],
                        expression:
                          "['positionId', { rules: [{ required: true, message: '请选择职务' }] }]",
                      },
                    ],
                    attrs: {
                      returnId: "",
                      placeholder: "请选择职务",
                      multiple: false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.departDisabled,
                      expression: "!departDisabled",
                    },
                  ],
                  attrs: {
                    label: "部门分配",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("role-tree", {
                    attrs: { defaultDepartKeys: _vm.defaultDepartKeys },
                    on: { change: _vm.treeChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("depart-window", { ref: "departWindow", on: { ok: _vm.modalFormOk } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEdit,
              expression: "isEdit",
            },
          ],
          staticClass: "drawer-bootom-button",
        },
        [
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "确定放弃编辑？",
                okText: "确定",
                cancelText: "取消",
              },
              on: { confirm: _vm.handleCancel },
            },
            [
              _c("a-button", { staticStyle: { "margin-right": "0.8rem" } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }